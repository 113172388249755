<template>
  <div class="form">
    <section class="form-section">
      <p class="form-headline">ログイン情報</p>
      <FormRow :required="true">
        <template v-slot:label>ユーザー名</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="form-group">
              <div class="form-group-item">
                <input
                  class="form-control"
                  :class="{ 'is-error': errors['nameSei'] }"
                  type="text"
                  name="nameSei"
                  v-maxlength
                  maxlength="100"
                  placeholder="姓"
                  v-model="formData.nameSei"
                />
              </div>
              <div class="form-group-item">
                <input
                  class="form-control"
                  :class="{ 'is-error': errors['nameMei'] }"
                  type="text"
                  name="nameMei"
                  v-maxlength
                  maxlength="100"
                  placeholder="名"
                  v-model="formData.nameMei"
                />
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>メールアドレス / ID</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="row">
              <div class="col-6">
                <input
                  class="form-control"
                  :class="{ 'is-error': errors['email'] }"
                  type="text"
                  name="email"
                  v-email
                  v-trim
                  v-maxlength
                  maxlength="50"
                  v-model="formData.email" />
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>初回パスワード</template>
        <template v-slot:labelNote><span class="text-note">（8文字以上で半角英数字および記号（/ \ ¥ を除く）を全て組み合わせ）</span></template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="row">
              <div class="col-6">
                <div class="form-password">
                  <input
                    class="form-control form-password-input"
                    :class="{ 'is-error': errors['password'] }"
                    :type="pwdVisible ? 'text' : 'password'"
                    name="password"
                    v-trim
                    v-model="formData.password"
                  />
                  <label class="form-check form-password-toggle">
                    <input class="form-check-input" type="checkbox" value="" v-model="pwdVisible" />
                    <i class="aikon form-password-icon"></i>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>イベント名</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="form-select">
              <select class="form-control form-select-input" :class="{ 'is-error': errors['eventName'] }" v-model="formData.eventName" >
                <option value="">選択してください</option>
                <option v-for="(event, index) in parentEventList" :key="index" :value="event.subdomain">
                  {{ event.name }}
                </option>
              </select>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>店舗名</template>
        <template v-slot:content>
          <div class="form-content-row">
            <SearchSelect
              :isError="errors['shopId']"
              ref="searchSelect"
              :options="storeList"
              :getLabel="getStoreLabel"
              :closeOnSelect="true"
              :disabled="!formData.eventName"
              :searchKeyMinLength="searchKeyLength"
              :multiple="false"
              :searchAction="searchAction"
              :resetOptions="resetOptions"
              :params="params"
              :searchField="searchField"
              @change-selection="storeNameSelected"
            />
          </div>
        </template>
      </FormRow>
      <UserMailSetting :formData="formData" @updateData="updateData"/>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, email, maxLength } from 'vuelidate/lib/validators';
import password from '@/mixins/plugin/password';
import error from '@/mixins/plugin/error';
import { isAlphanumSpecialWithMinLength } from '@/helpers/validators';
import { StatusConstants } from '@/constants/status';
//component
import FormRow from '@/components/FormRow.vue';
import SearchSelect from '@/components/SearchSelect.vue';
import UserMailSetting from '@/components/UserMailSetting.vue';
import userMailSetting from '@/mixins/module/userMailSetting';
//constant
import { RoleConstants } from '@/constants/role';
import { EventConstants } from '@/constants/event';

export default {
  name: 'SettingUserRegisterShop',
  data: function() {
    return {
      formData: {
        nameSei: '',
        nameMei: '',
        email: '',
        password: '',
        eventName: '',
        storeName: '',
        shopId: null,
        receptionEmailFlag: 1,
        authEmailFlag: 0
      },
      searchAction: 'shop/getShopList',
      resetOptions: 'shop/RESET_SHOP_LIST',
      params: {
        status: StatusConstants.shop.approved.value,
      },
      searchField: 'name',
    };
  },
  validations() {
    const formData = {
      nameSei: { required, maxLength: maxLength(100) },
      nameMei: { required, maxLength: maxLength(100) },
      email: { required, email, maxLength: maxLength(50) },
      password: { required, alphanumSpecialWithMinLength: isAlphanumSpecialWithMinLength(8) },
      eventName: { selectRequired: required },
      shopId: { selectRequired: required },
    };
    return { formData };
  },
  computed: {
    ...mapGetters({
      parentEventList: 'event/parentEventList',
      storeList: 'shop/shopList',
    }),
    searchKeyLength() {
      return EventConstants.SEARCH_KEY_MIN_LENGTH;
    },
  },
  mixins: [password, error, userMailSetting],
  components: {
    FormRow,
    SearchSelect,
    UserMailSetting,
  },
  methods: {
    async register() {
      if (this.$v.$invalid) {
        this.getErrors(this.$v.formData, 'user');
      } else {
        this.errors = {};
        let data = {
          ...this.formData,
          shopId: this.formData.shopId,
          subdomain: this.formData.eventName,
          authEmailFlag: this.formData.authEmailFlag ? 1 : 0,
          receptionEmailFlag: this.formData.receptionEmailFlag ? 1 : 0,
        };
        delete data.storeName;
        delete data.eventName;
        const result = await this.$store.dispatch('settingUser/createUser', { data: data, kind: RoleConstants.ROLE_STORE });
        if (result) {
          await this.$router.push({ name: 'SettingUser' });
          this.$message.showSuccess('createdUserInfoSuccess');
        }
      }
    },
    storeNameSelected(shop) {
      this.formData.shopId = shop.id;
    },
    getStoreLabel({ name }) {
      return name;
    },
  },
  watch: {
    'formData.eventName'(subdomain) {
      this.$refs.searchSelect.resetSelection();
      this.formData.shopId = '';
      this.$store.commit(this.resetOptions);
      if (subdomain) {
        this.params = {
          ...this.params,
          subdomain: subdomain,
        };
      }
    },
  },
  mounted: async function() {
    const loading = this.$loading.show();
    await this.$store.dispatch('event/getParentEventList', { limit: 9999 });
    this.$loading.clear(loading);
    this.$emit('footer-show');
  },
};
</script>
